import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gameAction } from "redux/modules";
import { Redirect, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Logo from "./assets/images/ramadan/logo.png";
import { imageShowUrl } from "../../../config/api.config";
import { useWindowSize } from "@react-hook/window-size";
// Menu Icon
import { IoMenuOutline, IoCloseOutline } from "react-icons/io5";

const Competition = props => {
  const dispatch = useDispatch();
  var disclaimer = null;
  const [width, height] = useWindowSize();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const [disclaimerList, setDisclaimerList] = useState([]);
  const [disclaimerInTheWeek, setDisclaimerInTheWeek] = useState(false);
  const [load, setLoad] = useState(false);
  const activeMenuItem = useSelector(store => store.game.indexMenu);

  const setIndexMenu = index => {
    dispatch(gameAction.setIndexMenu(index));
  };

  useEffect(() => {
    if (!disclaimerList || disclaimerList.length === 0) {
      dispatch(
        gameAction.getCompetitionDisclaimerByFirmId(
          employeeFirm.firm.id,
          employeeFirm.id
        )
      ).then(response => {
        console.log("response: ", response);

        response.data[0].menuList.forEach((element, index) => {
          if (element.path === location.pathname) {
            setIndexMenu(index);
          }
        });

        setDisclaimerList(response.data);
        props.getActionList(response.data);
      });
    }

    dispatch(
      gameAction.getDisclaimerByFirmId(employeeFirm.firm.id, employeeFirm.id)
    ).then(response => {
      if (response.data) {
        setDisclaimerInTheWeek(response.data[0].checkedDisclaimerInTheWeek);
        setLoad(true);
      }
    });

    return () => {};
  }, []);

  let location = useLocation();

  if (!disclaimerInTheWeek && load) {
    return <Redirect to="/disclaimer" />;
  }

  if (disclaimerList) {
    disclaimer = disclaimerList.map((item, index) => {
      if (item.memberStatu === "ASKIDA") {
        return <Redirect to="/" />;
      } else {
        if (item.checkedDisclaimerCompetition) {
          return (
            <div className="menu m-0 p-0" key={index}>
              <div className="container-xl">
                <nav className="navbar navbar-expand-md navbar-dark p-0 m-0">
                  <a href="/" className="md:!hidden">
                    <span className="navbar-brand p-0 m-0">
                      <img
                        src={imageShowUrl + item.logoImage}
                        alt=""
                        className="img-fluid mw-150"
                      />
                    </span>
                  </a>

                  {item.primaryColor && (
                    <button
                      className={`navbar-toggler !bg-[${item.primaryColor}] !m-0`}
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarNav"
                      aria-controls="navbarNav"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <IoMenuOutline className={`h-6 w-6 text-white`} />
                    </button>
                  )}

                  <div className="collapse navbar-collapse" id="navbarNav">
                    <div className="flex justify-between items-center !pl-4 !pr-6">
                      <div>
                        <a href="/" className="block !m-0 !p-0">
                          <span className="navbar-brand !m-0 !p-0">
                            <img
                              src={imageShowUrl + item.logoImage}
                              alt=""
                              className="!m-0 !p-0 img-fluid mw-150"
                            />
                          </span>
                        </a>
                      </div>

                      <button
                        className="navbar-toggler w-auto !my-4 !mx-0 !p-2"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <IoCloseOutline className={`h-6 w-6 text-white`} />
                      </button>
                    </div>

                    <style>
                      {`.navbar-nav li:hover {
                          background: ${item.choiceOutColor};
                          border-radius: 8px !important;
                        }`}
                    </style>

                    <ul className="navbar-nav !m-0 !px-6">
                      {item.menuList &&
                        item.menuList.map((menuItem, menuIndex) => {
                          const activeClass = {
                            backgroundColor: item.choiceOutColor
                          };

                          const defaultClass = {
                            color:
                              item &&
                              item.primaryColor &&
                              menuItem &&
                              menuItem.textColor
                                ? menuItem.textColor
                                : item.primaryColor
                          };

                          const aElemActiveClass = {
                            color: menuItem.textColor,
                            backgroundColor: item.textColor
                          };

                          return (
                            <li
                              key={menuIndex}
                              id={menuIndex}
                              className={
                                menuIndex === activeMenuItem
                                  ? "nav-item active"
                                  : "nav-item"
                              }
                              style={
                                menuIndex === activeMenuItem
                                  ? activeClass
                                  : null
                              }
                            >
                              <Link
                                className="nav-link"
                                to={{
                                  pathname: menuItem.path
                                  /*logined: "logined",
                                  brandId: 149465,
                                  brandIndex: 2,
                                  handleImgClick: true*/
                                }}
                                onClick={() => setIndexMenu(menuIndex)}
                                style={
                                  menuIndex === activeMenuItem
                                    ? aElemActiveClass
                                    : defaultClass
                                }
                              >
                                {menuItem.name}
                              </Link>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          );
        } else {
          if (location.pathname != "/competition/rules") {
            return <Redirect to="/competition/rules" />;
          } else {
            return (
              <div className="menu m-0 p-0" key={index}>
                <div className="container-xl">
                  <nav className="navbar navbar-expand-md navbar-dark">
                    <a href="/">
                      <span className="navbar-brand">
                        <img
                          src={imageShowUrl + item.logoImage}
                          alt=""
                          className="m-2 img-fluid mw-150"
                        />
                      </span>
                    </a>
                  </nav>
                </div>
              </div>
            );
          }
        }
      }
    });
  } else {
    if (location.pathname != "/competition/rules") {
      disclaimer = <Redirect to="/competition/rules" />;
    } else {
      disclaimer = (
        <div className="menu m-0 p-0">
          <div className="container-xl">
            <nav className="navbar navbar-expand-md navbar-dark">
              <a
                className="navbar-brand p-0"
                href="/competition/correct-responders"
              >
                <img src={Logo} alt="" />
              </a>
            </nav>
          </div>
        </div>
      );
    }
  }

  return <div>{disclaimer}</div>;
};
export default Competition;
