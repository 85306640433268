import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { authAction, disclaimerAction } from "redux/modules";
import { usePrevious } from "./index";

export default function useHandleRouteWhenAuth() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const isAuthenticated = useSelector(store => store.auth.isAuthenticated);
  const isPrevAuthenticated = usePrevious(isAuthenticated);
  const employeeFirm = useSelector(store => store.auth.employeeFirm);

  const disclaimerList = useSelector(store => store.disclaimer.disclaimerList);
  const [disclaimerShowed, setDisclaimerShowed] = useState(false);

  useEffect(() => {
    if (
      disclaimerList &&
      disclaimerList.filter(item => item.checkedDisclaimerInTheWeek !== true)
    ) {
      localStorage.removeItem("saveDisclaimer");
      localStorage.setItem("disclamerForce", true);
      setDisclaimerShowed(true);
      history.push("/disclaimer");
    }
    return () => {};
  }, [disclaimerList, history]);

  useEffect(() => {
    if (
      !isPrevAuthenticated &&
      isAuthenticated &&
      !disclaimerShowed &&
      location.pathname !== "/login" &&
      localStorage.getItem("saveDisclaimer") !== null
    ) {
      const nextRoute = location.pathname || "/";

      dispatch(disclaimerAction.getIysDisclaimerByErc(employeeFirm.ercCode))
        .then(res => {
          if (res.isHttpSuccess) {
            if (res?.data) {
              dispatch(
                authAction.returnNewScoreOfEmployee(
                  employeeFirm.firm.id,
                  employeeFirm.id
                )
              );

              if ("confirmed" in res.data) {
                dispatch(
                  disclaimerAction.getDisclaimerPhotoByFirmId(employeeFirm.id)
                )
                  .then(resPhoto => {
                    if (resPhoto.isHttpSuccess) {
                      if (resPhoto?.data?.length > 0) {
                        if ("confirmed" in resPhoto.data[0]) {
                          history.push("/");
                        } else {
                          history.push("/disclaimerPhoto");
                        }
                      } else {
                        history.push(nextRoute);
                      }
                    } else {
                      console.error(
                        "HTTP request failed for disclaimer photo."
                      );
                      history.push(nextRoute);
                    }
                  })
                  .catch(err => {
                    console.error(
                      "An error occurred while fetching the disclaimer photo:",
                      err
                    );
                    history.push(nextRoute);
                  });
              } else {
                history.push("/disclaimerIys");
              }
            }
          } else {
            console.error("HTTP request failed for IYS disclaimer.");
          }
        })
        .catch(err => {
          console.error(
            "An error occurred while fetching the IYS disclaimer:",
            err
          );
          history.push(nextRoute);
        });
    }
    return () => {};
  }, [
    isAuthenticated,
    isPrevAuthenticated,
    location,
    history,
    disclaimerShowed
  ]);

  useEffect(() => {
    if (
      !disclaimerShowed &&
      location.pathname !== "/disclaimer" &&
      localStorage.getItem("saveDisclaimer") === null &&
      location.pathname !== "/passwordReset" &&
      location.pathname !== "/login" &&
      location.pathname !== "/signUp" &&
      employeeFirm &&
      employeeFirm.disclaimerChecked === false
    ) {
      localStorage.removeItem("saveDisclaimer");
      localStorage.setItem("disclamerForce", true);
      setDisclaimerShowed(true);
      history.push("/disclaimer");
    } else if (
      !disclaimerShowed &&
      location.pathname !== "/disclaimer" &&
      isAuthenticated &&
      location.pathname === "/passwordReset" &&
      location.pathname === "/login" &&
      location.pathname === "/signUp"
    ) {
      dispatch(disclaimerAction.getIysDisclaimerByErc(employeeFirm.ercCode))
        .then(res => {
          if (res.isHttpSuccess) {
            dispatch(
              authAction.returnNewScoreOfEmployee(
                employeeFirm.firm.id,
                employeeFirm.id
              )
            );

            if (res.data) {
              history.push("/disclaimerIys");
            } else {
              dispatch(
                disclaimerAction.getDisclaimerPhotoByFirmId(employeeFirm.id)
              )
                .then(resPhoto => {
                  if (resPhoto.isHttpSuccess) {
                    if (resPhoto?.data?.length > 0) {
                      if ("confirmed" in resPhoto.data[0]) {
                        history.push("/");
                      } else {
                        history.push("/disclaimerPhoto");
                      }
                    } else {
                      history.push("/");
                    }
                  }
                })
                .catch(resPhotoError => {
                  if (resPhotoError) {
                    history.push("/");
                  }
                });
            }
          }
        })
        .catch(error => {
          if (error) {
            history.push("/");
          }
        });
    }
  }, [employeeFirm, history, isAuthenticated, location, dispatch]);
}
